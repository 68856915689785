<template>
  <div class="px-4 py-2 robot">
    <div class="py-4 font-bold text-black border-b border-gray-300 title">
      机器人管理
    </div>
    <div class="py-6 content_item text-a-right">
      <div class='tanchuangc' v-if="tanchuang">
        <div v-if="typeadd" class="add_bot">添加机器人</div>
        <div v-if="typeedit" class="add_bot">修改机器人</div>
        <div @click="close" class="icons-list"><a-icon type="close" /></div>
        <div class="bot_base">机器人基本信息</div>
        <div class=" mt-4 mb-4 flex">

          <div class="flex ppp111">             
             <div class="flex text-a-right ppp111str">机器人名称：</div>
             <div class="flex ppp111"><a-input size="small" class="h30" placeholder="请输入" v-model="modeln.name" /></div></div>
             <div class="flex ppp111">
             <div class="flex text-a-right ppp111str">机器人编码：</div>
             <div class="flex ppp111"><a-input class="h30" size="small" placeholder="请输入" v-model="modeln.code" /></div>
            </div>
        </div>
        <div class="mt-4 mb-4 flex">

       
            <div class="flex ppp111">
             <div class="flex text-a-right ppp111str">所属模型：</div>
             <div class="flex ppp111">
              
            <div  style="float:left;width:170px;height:30px" class="input_box">
               <a-select
              ref="select"
              v-model:value="modeln.model"
              style="width: 170px;height:30px"
              
              @change="handleChange"
              >
              <a-select-option  v-for="(val) in modelTypeList"  :value="val.id">
                {{ val.name }}
              </a-select-option>
                     
              </a-select>
            </div>
            
            </div>
            </div>

            <div class="flex ppp111">

            </div>
        </div>

        <div class="mt-4 mb-4 flex">


</div>

    <div style="text-align:right;" class="rob_button py-2">
          <a-button size="small" @click="close">关闭</a-button>
          <span class="px-2"></span>
          <a-button size="small" type="primary" @click="save">
            保存
          </a-button>
        </div>
 

        <div class="mt-4 mb-4 liebiao">

        </div>


      </div>
 
      <a-button size="small" type="primary" @click="addDiv">
            添加
      </a-button>

      <div class="pt-4">
        <a-table
          :data-source="dataSource"
          :columns="columns"
          :pagination="pagination"
        >
        

          <template slot="operation" slot-scope="text, record">
            <div
   
              @click="() => addDive(record.id)"
            >
            
              <a href="javascript:;" style="color: #00f;margin-right:10px;float:left;">修改</a>
          </div>
          <div
   
            @click="() => onStop(record.id)"
          >
          
            <a href="javascript:;" style="color: #00f;margin-right:10px;float:left;">停用</a>
          </div>
          </template>
          <template slot="moxingtion" slot-scope="text, record">

            <div v-for="(val) in modelTypeList">
            
            <div v-if="val.id == record.model">{{ val.name }}</div>
            
              
          </div>
      
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import {
  addRobot,
  //
  ruleRobot,
  //
  robotList,
  //
  modelList,
  changeRobot,
  changeRobotUp,
  deleteRobot,
  operatoinRobot,
  workRobot,
  disableRobot,

  modelTypeList,
  newrobotList,
   newrobotAdd,
   newrobotEdit,
   newrobotInfo,
   newrobotDesabled,
} from "@/api/robot";
import moment from "moment";


export default {
  data() {
    return {
      modeln:{name:'',code:'',model:''},
      typeadd:true,
      typeedit:false,
      xiugai:false,
      page: 1,
      modelTypeList:'',
      xiugaiDefault:1,
      limit: 10,
      value: "",
      xiugailaiyuanObj:'',
      xiugaichuanshuObj:'',
      value2: "",
      start_time: "",
      end_time: "",
      start_date: "",
      end_data: "",
      defaultValue: "",
      robotCode: "",
      rangeDate: "",
      model: 1,
      duiyingguanxi:[],
      money: "",
      name: "",
      index:0,
      threerobot:'',
      dataSource: [],
      count: 2,
      nanmoshang:'',
      nvmoshang:'',
      tongyongxia:'',
      tanchuang:false,
      xiugailaiyuanCfg:'',
      chuanzhiId:'',
      pagination: {
        // 关于页码的配置属性
        total: 0,
        pageSize:8,
        pageNo: 1,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) =>
          this.onSizeChange(current, pageSize), // 改变每页数量时更新显示
        onChange: (page, pageSize) => this.onPageChange(page, pageSize), //点击页码事件
      },
      columns: [
        {
          title: "机器人Id",
          dataIndex: "id",
        },
        {
          title: "所属模型",
          dataIndex: "moxingtion",
          scopedSlots: { customRender: "moxingtion" },
        },
        {
          title: "机器人名称",
          dataIndex: "name",
        },
        {
          title: "机器人编码",
          dataIndex: "code",
        },
      

        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
    };
  },
  created() {
    this.aquireRobotList();
    this.duiyingguanxif()
  },
  methods: {
    onStop(id){
      let ida =id
      newrobotDesabled({ids:[ida]}).then((res)=>{
        if(res.code ==200){
          this.$message.success("停用成功");
        }
      })
    },
    save(){
      if(this.typeadd == true){
        console.log(this.modeln)
    if(this.modeln.name !=='' && this.modeln.code !==''){
      newrobotAdd(this.modeln).then((res)=>{
        console.log(res)
        if(res.code == 200){
          this.$message.success("添加成功");
          this.tanchuang=false
      this.xiugai = false
      windows.reload()
        }
      })
    }else{
      this.$message.error("请填写信息");
    }
   }
   if(this.typeedit == true){
  this.modeln.id = this.xiugaiid
    if(this.modeln.name !=='' && this.modeln.code !==''){
      newrobotEdit(this.modeln).then((res)=>{
        console.log(res)
        if(res.code == 200){
          this.$message.success("修改成功");
          this.tanchuang=false
      this.xiugai = false
      windows.reload()
        }
      })
    }else{
      this.$message.error("请填写信息");
    }

  }

  
    },
    addDiv(){
      this.modeln.name = ''
      this.modeln.code = ''
      this.typeadd = true
      this.typeedit = false
       this.tanchuang = true;
    },
    addDive(id){
      this.modeln.model_type = id
      this.xiugaiid = id
      newrobotInfo({id:id}).then((res)=>{

        if(res.code == 200){
          this.modeln.name = res.data.name
          this.modeln.code = res.data.code
          this.modeln.model = res.data.model
         
        }
      
      })
      this.typeadd = false
      this.typeedit =true
       this.tanchuang = true;
    },
    disabledDate(current){
      return current && current <moment().subtract(1,'days').endOf('day')
    },
    disabledRangeTime (current) {
      const hours = moment().hours();
      const minutes = moment().minutes();
      const seconds = moment().seconds();
      if (current && moment().date() == current.date()) {
        return {
          disabledHours: () => this.range(0, hours),
          disabledMinutes: (selectedHour) =>
            selectedHour <= hours ? this.range(0, minutes) : [],
          disabledSeconds: (selectedHour, selectedMinute) =>
            selectedHour <= hours && selectedMinute.value <= minutes
            ? this.range(0, seconds)
            : []
        };
      }
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
        disabledSeconds: () => [],
      };
    },

    close(){
      this.tanchuang=false
      this.xiugai = false
    },
    // 分页里size改变触发的函数
    onSizeChange(_, pageSize) {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = pageSize;
      this.aquireRobotList();
    },
    // 页面改变时
    onPageChange(page, pageSize) {
      console.log(page, pageSize);
      this.pagination.pageNo = page;
      this.aquireRobotList();
    },
    onRangeChange(date, dateString) {
      console.log(dateString);
      this.start_date = dateString[0];
      this.end_date = dateString[1];
    },

    reset() {
      this.value = "";
      this.value2 = "";
      this.start_time = "";
      this.end_time = "";
      this.robotCode = "";
      this.money = "";
      this.name = "";
    },
    handleChange(value) {
      console.log(`selected ${value}`);
      this.modeln.model = value;


      //console.log(this.threerobt)
      //this.threerobt = nanmoshang
    },
    handleChangex(value) {
      console.log(`selected ${value}`);
      this.chuanzhiId= value

    },
    getCurrentStyle(current, today) {
      const style = {};
      if (current.date() === 1) {
        style.border = "1px solid #1890ff";
        style.borderRadius = "50%";
      }
      return style;
    },
    onChange(time, string) {
      console.log(time, string);
      this.value = time;
      this.start_time = string;
    },
    onChange2(time, string) {
      console.log(time, string);
      this.value2 = time;
      this.end_time = string;
    },
    onCellChange(key, dataIndex, value) {
      const dataSource = [...this.dataSource];
      const target = dataSource.find((item) => item.key === key);
      if (target) {
        target[dataIndex] = value;
        this.dataSource = dataSource;
      }
    },
    // 删除
    onDelete(key) {
      console.log(key, this.dataSource[key]);
      deleteRobot({
        id: this.dataSource[key].id,
      })
        .then((res) => {
          console.log(res);
          if (res.msg == "操作成功") {
            this.$message.success("删除机器人成功");
            const dataSource = [...this.dataSource];
            this.dataSource = dataSource.filter((item) => item.key !== key);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onXiugai(key){
      console.log(key)
      
      changeRobot({
        id: this.dataSource[key].id,
      }).then(res=>{
        console.log(res)
        this.xiugailaiyuanObj = res.data
        this.xiugailaiyuanCfg = JSON.parse(res.data.cfg)
        //this.xiugailaiyuanCfg = res.data.cfg)
        console.log(this.xiugailaiyuanCfg )
        this.xiugaiDefault = res.data.model
        this.chuanzhiId= res.data.model
        console.log(this.xiugaiDefault)
        this.xiugai = true;

      })
    },

 
    handleAdd() {
      const { count, dataSource } = this;
      const newData = {
        key: count,
        name: `Edward King ${count}`,
        age: 32,
        address: `London, Park Lane no. ${count}`,
      };
      this.dataSource = [...dataSource, newData];
      this.count = count + 1;
    },
    // 获取规则

    // 获取机器人列表
    aquireRobotList() {

      // ruleRobot().then((res)=>{
      //   console.log(res)
      //    this.nanmoshang = res.data[0].attr
      //    this.nvmoshang = res.data[1].attr
      //    this.tongyongxia = res.data[2].attr
      //   //this.threerobot = JSON.parse(res.data)
      //   this.threerobot = res.data
      //   console.log(this.threerobot)
      //   for(let key in this.threerobot){
      //   let id = this.threerobot[key].id
      //   let name = this.threerobot[key].name
      //   this.duiyingguanxi[id] = name
      // }
      // console.log(this.duiyingguanxi)
      //   console.log(res.data)
      // }).catch((error)=>{
      //   console.log('eee')
      //   console.log(error)
      // })
      modelList().then((res)=>{
        if(res.code ==200){
          this.modelTypeList = res.data.list
          // res.data.list.map(val=>{
          //   let inarr =
          // this.modeln.model_type.push(iarr)
          // })
        }
      })
      newrobotList()
        .then((res) => {
          console.log('jqiren')
           console.log(res);
          let result = res.data;
          this.pagination.total = result.count;
          result.list.map((item, index) => {
            item.key = index;
            item.date = item.start_date + "~" + item.end_date;
            item.time = item.start_time + "~" + item.end_time;
          });
          this.dataSource = [];
          this.dataSource.push(...result.list);
          console.log(this.dataSource)
          this.pagination.total = res.data.count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    duiyingguanxif(){
      
      for(let key in this.threerobot){
        let id = this.threerobot[key].id
        let name = this.threerobot[key].name
        this.duiyingguanxi[id] = name
      }
      console.log(this.duiyingguanxi)
    },
    // 添加机器人12313123123
    addRobotEvent() {
      if (this.name == "" || this.isNull(this.name)) {
        this.$message.error("机器人名称不能为空");
        return;
      }
      if (this.robotCode == "" || this.isNull(this.robotCode)) {
        this.$message.error("机器人编码不能为空");
        return;
      }
      if (this.robotCode.length < 6) {
        this.$message.error("机器人编码长度不能低于6位");
        return;
      }
      if (this.start_date == "") {
        this.$message.error("请选择工作日期");
        return;
      }
      if (this.start_time == "") {
        this.$message.error("请选择工作开始时间");
        return;
      }
      if (this.end_time == "") {
        this.$message.error("请选择工作结束时间");
        return;
      }
      if (this.money == "" || this.money == 0) {
        this.$message.error("价格不能为空或者为0");
        return;
      }
      addRobot({
        model: this.model,
        name: this.name,
        code: this.robotCode,
        start_time: this.start_time,
        end_time: this.end_time,
        start_date: this.start_date,
        end_date: this.end_date,
        money: this.money,
        shape_cfg:JSON.stringify(this.threerobot[this.index].attr)
        //shape_cfg:this.threerobot[this.index].attr
      })
        .then((res) => {
          console.log(res);
          if (res.msg == "操作成功") {
            this.$message.success("机器人添加成功");
            this.aquireRobotList();
            this.reset();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    xiugaiRobotEvent(){
      //console.log(this.xiugailaiyuanObj.id)
      console.log(this.chuanzhiId)
      console.log(this.duiyingguanxi[this.chuanzhiId])
      console.log(this.xiugailaiyuanObj.name)
      console.log(this.xiugailaiyuanObj.money)
      console.log(this.xiugailaiyuanCfg)
      
      let aaa = {model:this.chuanzhiId,title:this.duiyingguanxi[this.chuanzhiId],name:this.xiugailaiyuanObj.name,money:this.xiugailaiyuanObj.money,shape_cfg:this.xiugailaiyuanCfg}
      console.log(aaa)

      let jjjsss = JSON.stringify(this.xiugailaiyuanCfg)
      changeRobotUp({id:this.xiugailaiyuanObj.id,model:this.chuanzhiId,title:this.duiyingguanxi[this.chuanzhiId],name:this.xiugailaiyuanObj.name,money:this.xiugailaiyuanObj.money,shape_cfg:jjjsss}).then(res=>{
        console.log(res)
        if(res.msg == '操作成功'){
          alert('修改成功')
        }
      
      })
      //console.log(changeRobotUp)
    },
    // 判断是否为空
    isNull(str) {
      if (str == "") return true;
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      return re.test(str);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
}
.text-a-right{text-align:right;}
.content_item {
  .item {
    .item_title {
      font-size: 16px;
    }
    span {
      font-size: 14px;
    }
    .list_title {
      color: #000000;
    }
    .list_msg {
      color: #7c7c7c;
    }
    div {
      font-size: 14px;
      color: #000000;
    }
    .item_box {
      div {
        width: 25%;
      }
    }
    span {
      font-size: 14px;
    }
  }
}
</style>
<style lang="less">
  .liebiao1{width:50%;float:left;}
  .sg_input{width:130px;}
// .ant-time-picker{width:142px!important;}
  .ppp111str{width:100px;    justify-content: right;    float: left;}
  .ppp111{flex:1 ;justify-content: flex-end;}
.rob_str{flex:1}  
.input_box{flex:1}
.bm_right{margin-right:7px;}  
  .tixing{text-align: left;width:25%;float:left;}
  .bot_input{width:2rem;}
  .rob_button{clear:both;text-align: center;}
.robot {
  .editable-cell {
    position: relative;
  }
  .add_bot{
text-align: left;font-size: 1.2rem;margin-top:-10px;line-height: 50px;padding-left:15px;
  }
  .bot_base{font-size:0.9rem;text-align: left;background-color: #F6F9FE;line-height: 30px;color:#000;font-weight: 600;padding-left:15px;}
  .icons-list {    position: absolute;
    right: 10px;
    top: 10px;}
  .icons-list .anticon {
  margin-right: 6px;
  font-size: 24px;
}
.tanchuangc{width: 35%;
    padding: 20px;
    margin: 0 auto;
    left: 30%;
    background-color: #fefefe;
    z-index: 90;
    border-radius:20px;
    position: absolute;
    border: 0px solid #ccc;}
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    line-height: 18px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell:hover .editable-cell-icon {
    display: inline-block;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
  tr td:nth-child(4) {
    text-align: right;
    a {
      color: red;
    }
  }
  th:nth-child(4) {
    text-align: right;
  }
}
</style>
